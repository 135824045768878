<template>
  <div id="sale-preview-qrcode-page" v-if="isLoad">
    <div class="grid grid-cols-12 pt-10">
      <div class="col-start-1 col-end-13 mr-auto ml-auto">
        <img v-show="isImgLoad" :src="imgUrl" class="border-2" @load="loaded" />
        <div v-show="!isImgLoad" class="loading">Loading...</div>
      </div>

      <div class="col-start-4 col-end-10">
        <button
          v-show="isImgLoad"
          class="border-ra rounded p-2 bg-green-400 hover:bg-green-500 text-white col-span-2 mt-10"
          @click="downloadQrCode"
        >
          <!-- <svg class="animate-bounce w-6 h-6 ..."></svg> -->
          บันทึก Qr Code
        </button>
        <button
          v-show="isImgLoad"
          class="border-ra rounded p-2 mt-5 sm:ml-5 sm:mt-10 bg-gray-400 hover:bg-yellor-500 text-white col-span-2"
          @click="backToMain"
        >
          <!-- <svg class="animate-bounce w-6 h-6 ..."></svg> -->
          กลับสู่หน้าหลัก
        </button>
        <input type="hidden" id="pincode" :value="pinCode" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SalePreviewQrocdePage",
  data() {
    return {
      isLoad: false,
      isImgLoad: false,
      imgUrl: "",
      paramsAppointment: {},
      pinCode: "",
    };
  },
  async created() {
    var qs = require("qs");
    const paramsAppointment = qs.parse(
      localStorage.getItem("paramsAppointment")
    );

    const params = {
      full_name:
        paramsAppointment.first_name + " " + paramsAppointment.last_name,
      project_name: paramsAppointment.project_name.th,
      project_code: paramsAppointment.project_code,
      seller: paramsAppointment.seller,
      contact_matter: paramsAppointment.contact_matter,
      mobile: paramsAppointment.mobile,
      appointment_date: paramsAppointment.appointment_date,
      appointment_start: paramsAppointment.appointment_start,
      appointment_end: paramsAppointment.appointment_end,
      url_qrcode: paramsAppointment.url_qrcode,
      pin_code: paramsAppointment.pin_code,
    };

    console.log("Request API GEN Appointment ::", params);

    const data = await this.$store.dispatch("sale/actionCreateImg", params);
    if (data) {
      this.imgUrl = data.image;
      this.isLoad = true;
      this.pinCode = paramsAppointment.pin_code;
      // await this.awaitLoadImg(loader);
    }
  },
  methods: {
    async downloadQrCode() {
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        lockScroll: true,
        zIndex: 9999,
        loader: "dots",
        color: "#006192",
      });

      const params = {
        imgUrl: this.imgUrl,
      };

      const data = await this.$store.dispatch(
        "sale/actionDownloadQrcode",
        params
      );

      if (data) {
        loader.hide();
        // this.$router.push({ name: "sale.genqr" });
        console.log(data);
      } else {
        console.log(data);
        console.log("err");
      }
    },
    backToMain() {
      this.$router.go(-1);
      localStorage.removeItem("paramsAppointment");
    },
    loaded() {
      this.isImgLoad = true;
    },
    // awaitLoadImg(loader) {
    //   setTimeout(function () {
    //     console.log("wait..");
    //     loader.hide();
    //     console.log("wait....");
    //   }, 1000);
    // },

    // getParamsAppointment() {
    //   this.paramsAppointment = {
    //     first_name: localStorage.getItem("firstName"),
    //     last_name: localStorage.getItem("lastName"),
    //     project_name: localStorage.getItem("projectName"),
    //     contact_matter: localStorage.getItem("contactMatter"),
    //     mobile: localStorage.getItem("mobile"),
    //     appointment_date: localStorage.getItem("appointmentDate"),
    //     appointment_start: localStorage.getItem("appointmentStart"),
    //     appointment_end: localStorage.getItem("appointmentEnd"),
    //     url_qrcode: localStorage.getItem("urlQrcode"),
    //     pin_code: localStorage.getItem("pinCode"),
    //   };
    // },
  },
};
</script>
